import React from 'react';
import './ComingSoon.css';

const ComingSoon = () => {
  return (
    <div className="coming-soon-container">
      <div className="coming-soon-content">
        <img 
          src="https://www.myfuturetoken.com/static/media/logo.cea35e187fac5e624014.png" 
          alt="MyFutureToken Logo" 
          className="coming-soon-logo" 
        />
        <h1>MyFutureToken</h1>
        <p>Demnächst Verfügbar</p>
        <div className="countdown">
          Wir sind bald da!
        </div>
      </div>
    </div>
  );
};

export default ComingSoon; 